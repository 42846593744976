
import { defineComponent } from 'vue';
import { propertyBaseRouter, propertySubRouter } from '@/router/data';
import breadCrumb from '@/components/common/bread-crumb';
import { activeCommunity } from '@/views/pm/util';

export default defineComponent({
    name: 'property-bread-crumb',
    props: {
        headers: {
            default() {
                return [];
            }
        }
    },
    components: {
        breadCrumb
    },
    setup() {
        const path = `/${propertyBaseRouter}/${propertySubRouter.home}`;

        return {
            path,
            activeCommunity
        };
    }
});
