import { ref, Ref } from 'vue';
import { community } from '@/data';

interface Option {
    key: string;
    value: string;
    [index: string]: string;
}

interface RoomOption {
    ID: string;
    RoomName: string;
    RoomNumber: string;
    UnitID: string;
    Floor: string;
}

interface BuildingOption {
    ID: string;
    UnitName: string;
    Floor?: string;
}

const init = (
    roomType: 'all' | 'empty' = 'all',
    roomOption: 'apt' | 'aptAndName' = 'apt',
    communityId?: string
) => {
    const buildOptions: Ref<Array<Option & {
        floor?: string;
    }>> = ref([]);
    const aptOptions: Ref<Array<Option>> = ref([]);

    let allBuilding: Array<BuildingOption> = [];
    let allRoom: Array<RoomOption> = [];
    const useRoom: Array<string> = [];

    const setBuildingOption = () => {
        buildOptions.value = [];
        allBuilding.forEach((item) => {
            if (item.Floor) {
                buildOptions.value.push({
                    key: item.ID,
                    value: item.UnitName,
                    floor: item.Floor
                });
            } else {
                buildOptions.value.push({
                    key: item.ID,
                    value: item.UnitName
                });
            }
        });
    };

    const setRoomOption = (buildKey = '', roomTypeTemp = roomType) => {
        aptOptions.value = [];
        allRoom.forEach((item) => {
            if (buildKey === '' || item.UnitID === buildKey) {
                let value = item.Floor ? `${item.RoomName} (${WordList.Floor} ${item.Floor}) ` : item.RoomName;
                if (roomOption === 'aptAndName') {
                    value = item.Floor
                        ? `${item.RoomName} ${item.RoomNumber} (${WordList.Floor} ${item.Floor}) ` : `${item.RoomName} ${item.RoomNumber}`;
                }
                if (roomTypeTemp === 'empty') {
                    if (!(useRoom.includes(item.ID))) {
                        aptOptions.value.push({
                            key: item.ID,
                            value,
                            floor: item.Floor
                        });
                    }
                } else {
                    aptOptions.value.push({
                        key: item.ID,
                        value,
                        floor: item.Floor
                    });
                }
            }
        });
    };

    community.getCommunityBr((data: {
        build: Array<BuildingOption>;
        room: Array<RoomOption>;
        useRoom: Array<RoomOption>;
    }) => {
        allBuilding = data.build;
        allRoom = data.room;
        data.useRoom.forEach((item) => {
            useRoom.push(item.ID);
        });
        setBuildingOption();
        setRoomOption();
    }, communityId);

    return {
        buildOptions,
        aptOptions,
        useRoom,
        setRoomOption
    };
};

export default init;
export {
    Option,
    BuildingOption,
    RoomOption
};
