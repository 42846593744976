import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bread_crumb = _resolveComponent("bread-crumb")

  return (_openBlock(), _createBlock(_component_bread_crumb, {
    baseLabel: _ctx.activeCommunity.label,
    headers: _ctx.headers,
    basePath: _ctx.path
  }, null, 8, ["baseLabel", "headers", "basePath"]))
}