import { ListHeaderItem, RequestData } from '@/components/common/list';

interface MessageType {
    Title: string;
    Name: string;
    Message: string;
}

interface MsgTemplateType {
    Name: string;
    Title: string;
    Message: string;
    ID: string;
    CreateTime: string;
}

type AddMessageData = {
    [key in ProjectType]: {
        header: Array<ListHeaderItem>;
        requestData: RequestData;
    }
}
// 办公、社区请求接口、列表区分
const getAddMessageData = () => {
    const communityHeader: Array<ListHeaderItem> = [{
        label: WordList.TabelPersonUserInHtmlUID,
        name: 'Account'
    }, {
        label: WordList.TabelPersonUserInHtmlName,
        name: 'Name2'
    }, {
        label: WordList.TabelPropertyManageEmail,
        name: 'Email'
    }, {
        label: WordList.DeviceDetailDetailUnitName,
        name: 'UnitName'
    }, {
        label: WordList.TabelPersonKeyInHtmlRoom,
        name: 'RoomName'
    }];
    const officeHeader: Array<ListHeaderItem> = [{
        label: WordList.TabelPersonUserInHtmlUID,
        name: 'Account'
    }, {
        label: WordList.TabelPersonUserInHtmlName,
        name: 'Name'
    }, {
        label: WordList.TabelPropertyManageEmail,
        name: 'Email'
    }, {
        label: WordList.ProperAllTextDepartment,
        name: 'DepartmentName'
    }];
    const communityRequestData: RequestData = {
        url: 'v3/web/community/user/getListForMessage',
        param: {
            Build: 'all',
            Key: ''
        }
    };
    const officeRequestData: RequestData = {
        url: 'v3/web/office/message/getOfficeMessageReceiver',
        param: {
            Department: 'all',
            Key: ''
        }
    };
    const addMessageData: AddMessageData = {
        community: {
            header: communityHeader,
            requestData: communityRequestData
        },
        office: {
            header: officeHeader,
            requestData: officeRequestData
        }
    };
    return addMessageData;
};

export default null;
export {
    MessageType,
    MsgTemplateType,
    getAddMessageData
};
