import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tip-button-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cus_button = _resolveComponent("cus-button")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_cus_button, {
      class: "width200px",
      type: _ctx.active ? 'blue' : 'default',
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emit('choose'))),
      onMouseover: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showHaning = true)),
      onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showHaning = false))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.name), 1)
      ]),
      _: 1
    }, 8, ["type"]),
    _withDirectives(_createVNode("div", {
      class: "tip-hanging-window",
      onMouseover: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showHaning = true)),
      onMouseleave: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showHaning = false))
    }, [
      _createVNode("span", {
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.emit('edit'))),
        class: "el-icon-my-edit2"
      }),
      _createVNode("span", {
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.emit('delete'))),
        class: "el-icon-delete"
      })
    ], 544), [
      [_vShow, _ctx.showHaning]
    ])
  ]))
}