
import { defineComponent, PropType } from 'vue';
import router from '@/router';

export default defineComponent({
    props: {
        baseLabel: {
            type: String,
            required: true
        },
        basePath: {
            type: String,
            required: true
        },
        headers: {
            type: Array as PropType< { path: string; label: string }[] >,
            default() {
                return [];
            }
        }
    },
    setup() {
        const change = (path?: string) => {
            if (path) router.push(path);
        };

        return {
            change
        };
    }
});
