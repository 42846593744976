
import dialogShell from '@/components/common/dialog-shell/index.vue';
import {
    defineComponent, ref, Ref, Prop, PropType, reactive
} from 'vue';
import { message } from '@/methods/rule';
import { community } from '@/data';
import HttpRequest from '@/util/axios.config';
import { MessageType } from '@/views/pm/message/util';
import Notice from '@/util/notice.base';

export default defineComponent({
    emits: ['close', 'success'],
    props: {
        submitType: {
            type: String,
            required: true
        },
        initForm: {
            type: Object as PropType<MessageType>,
            required: true
        }
    },
    setup(props, { emit }) {
        const form = reactive({ ...props.initForm });

        const rules = {
            Name: [{
                required: true,
                trigger: 'blur',
                message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelPersonUserInHtmlName)
            }, {
                validator: message.checkName,
                trigger: 'change'
            }],
            Title: [{
                required: true,
                message: WordList.RuleMessageTitleEmpty,
                trigger: 'blur'
            }, {
                validator: message.checkTitle,
                trigger: 'change'
            }],
            Message: [{
                required: true,
                trigger: 'blur',
                message: WordList.RuleMessageEmpty
            }, {
                validator: community.isNew.value ? message.checkMessage : message.checkOldCommunityMessage,
                trigger: 'change'
            }]
        };

        const close = () => {
            emit('close');
        };

        const msgForm: Ref<any> = ref(null);
        const submit = () => {
            msgForm.value.validate((valid: boolean) => {
                if (!valid) return false;
                const addTemplateUrl = community.ProjectType.value === 'community'
                    ? 'v3/web/community/messageTemplate/add'
                    : 'v3/web/office/messageTemplate/add';
                const modifyTemplateUrl = community.ProjectType.value === 'community'
                    ? 'v3/web/community/messageTemplate/edit'
                    : 'v3/web/office/messageTemplate/edit';
                const url = props.submitType === 'add' ? addTemplateUrl : modifyTemplateUrl;
                HttpRequest.post(url, form, (res: {
                    body: {
                        msg: string;
                    };
                }) => {
                    emit('success');
                    close();
                });
                return true;
            });
        };

        return {
            form,
            rules,
            msgForm,
            submit,
            close
        };
    }
});
