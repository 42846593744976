import { reactive } from 'vue';
import { isOffice } from '@/methods/role';

const ChangeWordList: {
    ProjectListTitle: string;
    Projects: string;
    NewProjects: string;
    PaymentsNumInList: string;
    SubscriptionActivate: string;
    SubscriptionSelectType: string;
    NavPeopleAndDevice: string;
    PeopleDeviceAddTitle: string;
    PeopleDeviceProject: string;
    PeopleDevicePeople: string;
    PeopleDeviceDepartmentPeople: string;
    DeviceContactFirstSetting: string;
    DeviceContactSecondSetting: string;
    DeviceContactThirdSetting: string;
    PMSetAccessBuildOrDepartment: string;
    PMBasicSettingProjectName: string;
    ApartmentOrDepartment: string;
    ResidentOrPeople: string;
    PMPersonnelToVisitor: string;
    PMPlaceHolderName: string;
    PMProjectInformation: string;
    DeviceDepartmentOrBuild: string;
    DeviceAllDepartmentOrBuild: string;
    PMImportFaceTips: string;
    PMCreatePin: string;
    ImportFace4: string;
} = reactive({
    ProjectListTitle: '',
    Projects: '',
    NewProjects: '',
    PaymentsNumInList: '',
    SubscriptionActivate: '',
    SubscriptionSelectType: '',
    NavPeopleAndDevice: '',
    PeopleDeviceAddTitle: '',
    PeopleDeviceProject: '',
    PeopleDevicePeople: '',
    PeopleDeviceDepartmentPeople: '',
    DeviceContactFirstSetting: '',
    DeviceContactSecondSetting: '',
    DeviceContactThirdSetting: '',
    PMSetAccessBuildOrDepartment: '',
    PMBasicSettingProjectName: '',
    ApartmentOrDepartment: '',
    ResidentOrPeople: '',
    PMPersonnelToVisitor: '',
    PMPlaceHolderName: '',
    PMProjectInformation: '',
    DeviceDepartmentOrBuild: '',
    DeviceAllDepartmentOrBuild: '',
    PMImportFaceTips: '',
    PMCreatePin: '',
    ImportFace4: ''
});

function changeText(scene: ProjectType) {
    switch (scene) {
    case 'office':
        ChangeWordList.ProjectListTitle = WordList.ProperAllTextListOfOffices;
        ChangeWordList.Projects = WordList.ProperAllTextOffices;
        ChangeWordList.NewProjects = WordList.ProperAllTextNewOffice;
        ChangeWordList.PaymentsNumInList = WordList.ProperAllTextNumOfPeople;
        ChangeWordList.SubscriptionActivate = WordList.ProperAllTextActivatePeople;
        ChangeWordList.SubscriptionSelectType = WordList.ProperAllTextSelectPeople;
        ChangeWordList.NavPeopleAndDevice = WordList.ProperAllTextPeopleAndDevice;
        ChangeWordList.PeopleDeviceAddTitle = WordList.ProperAllTextAddDepartment;
        ChangeWordList.PeopleDeviceProject = WordList.ProperAllOffice;
        ChangeWordList.PeopleDevicePeople = WordList.ProperAllTextPeople;
        ChangeWordList.PeopleDeviceDepartmentPeople = WordList.ProperAllTextPeople;
        ChangeWordList.DeviceContactFirstSetting = WordList.TabelDeviceInHtmlShowPeople;
        ChangeWordList.DeviceContactSecondSetting = WordList.TabelDeviceInHtmlShowIndoorGuardApp;
        ChangeWordList.DeviceContactThirdSetting = WordList.TabelDeviceInHtmlShowPeopleIndoorGuardApp;
        ChangeWordList.PMSetAccessBuildOrDepartment = WordList.ProperAllTextDepartment;
        ChangeWordList.PMBasicSettingProjectName = WordList.ProperAllTextOfficeName;
        ChangeWordList.ApartmentOrDepartment = WordList.ProperAllTextDepartment;
        ChangeWordList.ResidentOrPeople = WordList.TabelPersonKeyInHtmlUser;
        ChangeWordList.PMPersonnelToVisitor = WordList.OfficeVisitor;
        ChangeWordList.PMPlaceHolderName = WordList.TabelPersonUserInHtmlName;
        ChangeWordList.PMProjectInformation = WordList.ProperAllTextOfficeInformation;
        ChangeWordList.DeviceDepartmentOrBuild = WordList.ProperAllTextDepartments;
        ChangeWordList.DeviceAllDepartmentOrBuild = WordList.AllDepartments;
        ChangeWordList.PMImportFaceTips = WordList.OfficeImportFaceNameTips;
        ChangeWordList.PMCreatePin = WordList.AllowPeopleCreatePin;
        ChangeWordList.ImportFace4 = WordList.ImportFaceOfficeNo4;
        break;
    case 'community':
        ChangeWordList.ProjectListTitle = WordList.ProperAllTextListOfCommunities;
        ChangeWordList.Projects = WordList.ProperAllTextCommunities;
        ChangeWordList.NewProjects = WordList.TabelCommunitiesTitleAdd;
        ChangeWordList.PaymentsNumInList = WordList.ProperAllTextCounts;
        ChangeWordList.SubscriptionActivate = WordList.ProperAllTextActivation;
        ChangeWordList.SubscriptionSelectType = WordList.ProperAllTextNewSelectApartments;
        ChangeWordList.NavPeopleAndDevice = WordList.NavInHtmlMenuRDevice;
        ChangeWordList.PeopleDeviceAddTitle = WordList.RDeiviceAddBuild;
        ChangeWordList.PeopleDeviceProject = WordList.ProperAllCommunity;
        ChangeWordList.PeopleDevicePeople = WordList.RDeviceResidents;
        ChangeWordList.PeopleDeviceDepartmentPeople = WordList.RDeviceSearchLabelRoomName;
        ChangeWordList.DeviceContactFirstSetting = WordList.TabelDeviceInHtmlShowRoomNum;
        ChangeWordList.DeviceContactSecondSetting = WordList.TabelDeviceInHtmlShowIndoorApp;
        ChangeWordList.DeviceContactThirdSetting = WordList.TabelDeviceInHtmlShowIndoor;
        ChangeWordList.PMSetAccessBuildOrDepartment = WordList.DeviceDetailDetailUnitName;
        ChangeWordList.PMBasicSettingProjectName = WordList.TabelCommunitiesName;
        ChangeWordList.ApartmentOrDepartment = WordList.ProperAllTextApartments;
        ChangeWordList.ResidentOrPeople = WordList.ProperAllTextResident;
        ChangeWordList.PMPersonnelToVisitor = WordList.ProperAllTextCommunityPersonnel;
        ChangeWordList.PMPlaceHolderName = WordList.RDeviceSearchLabelRoomNumber;
        ChangeWordList.PMProjectInformation = WordList.ProperAllTextComInfo;
        ChangeWordList.DeviceDepartmentOrBuild = WordList.DarshbordBuild;
        ChangeWordList.DeviceAllDepartmentOrBuild = WordList.AllBuildings;
        ChangeWordList.PMImportFaceTips = WordList.ProperAllTextImportFace2Tips;
        ChangeWordList.PMCreatePin = WordList.ProperAllTextResidentCreatePin;
        ChangeWordList.ImportFace4 = WordList.ImportFaceNo4;
        break;
    default:
        break;
    }
}
// 接收当前场景类型
changeText('community');

const changeTextByScene = (scene: 'office' | 'community' | false = false) => {
    if (scene) {
        changeText(scene);
    } else {
        const role = isOffice() ? 'office' : 'community';
        changeText(role);
    }
};

export default changeTextByScene;
export {
    ChangeWordList
};
