import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_form = _resolveComponent("el-form")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_dialog_shell, {
      title: _ctx.submitType === 'add' ? _ctx.$wordList.ProperAllTextNewTemplate : _ctx.$wordList.ProperAllTextEditTemplate,
      onSubmit: _ctx.submit,
      onClose: _ctx.close
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref: "msgForm",
          model: _ctx.form,
          rules: _ctx.rules,
          class: "msg-dialog"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.$wordList.TabelPersonUserInHtmlName,
              prop: "Name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.form.Name,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.form.Name = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$wordList.MessageListTanleTitle,
              prop: "Title"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.form.Title,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.form.Title = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$wordList.TabelMessageBoxMessage,
              prop: "Message"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  rows: 5,
                  modelValue: _ctx.form.Message,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.form.Message = $event)),
                  type: "textarea"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["title", "onSubmit", "onClose"])
  ]))
}