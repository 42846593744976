
import {
    computed, defineComponent, Ref, ref
} from 'vue';
import messageTemplateDialog from '@/components/view/pm/message-template-dialog/index.vue';
import tipButton from '@/components/common/tip-button/index.vue';
import HttpRequest from '@/util/axios.config';
import Notice from '@/util/notice.base';
import { isNew } from '@/data/src/community';
import { community } from '@/data';
import { MessageType, MsgTemplateType } from './util';

export default defineComponent({
    emits: ['choose'],
    components: {
        messageTemplateDialog,
        tipButton
    },
    setup(props, { emit }) {
        const form = ref({});
        const submitType = ref('');

        const isShowMsgDialog = ref(false);
        const isShowAddBtn = computed(() => {
            // 旧社区只能创建6个模板
            if (!isNew.value) {
                return allTemplateList.value.length < 6;
            }
            if (allTemplateList.value.length > 6) {
                return showMoreTemplate.value && allTemplateList.value.length < 30;
            }
            return allTemplateList.value.length < 30;
        });

        // 添加新模版
        const add = () => {
            isShowMsgDialog.value = true;
            form.value = {
                Title: '',
                Name: '',
                Message: ''
            };
            submitType.value = 'add';
        };

        const showMoreTemplate = ref(false);

        // 获取模版数据
        const templateList = computed<Array<MsgTemplateType>>(() => {
            if (showMoreTemplate.value) {
                return allTemplateList.value;
            }
            return allTemplateList.value.slice(0, 6);
        });
        const allTemplateList: Ref<Array<MsgTemplateType>> = ref([]);
        const getMsgTemplate = () => {
            HttpRequest.get(`v3/web/${community.ProjectType.value}/messageTemplate/getAll`, {}, (res: {
                data: Array<MsgTemplateType>;
            }) => {
                allTemplateList.value = res.data;
            });
        };
        getMsgTemplate();

        // 选择目标模版
        const active = ref('');
        const choose = (item: MsgTemplateType) => {
            active.value = item.ID;
            emit('choose', item);
        };
        // 编辑模版
        const edit = (item: MsgTemplateType) => {
            form.value = item;
            isShowMsgDialog.value = true;
            submitType.value = 'modify';
        };
        // 删除目标模版
        const deleteItem = (item: MsgTemplateType) => {
            Notice.messageBox('confirm', WordList.ProperAllTextDeleteTemplateTips, WordList.TabelDeleteTitle, 'warning')(() => {
                HttpRequest.post(`v3/web/${community.ProjectType.value}/messageTemplate/delete`, {
                    ID: item.ID
                }, (res: {
                    body: {
                        msg: string;
                    };
                }) => {
                    getMsgTemplate();
                });
            });
        };

        return {
            add,
            form,
            edit,
            active,
            choose,
            deleteItem,
            submitType,
            showMoreTemplate,
            templateList,
            allTemplateList,
            getMsgTemplate,
            isShowMsgDialog,
            isShowAddBtn,
            isNew
        };
    }
});
