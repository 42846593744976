
import {
    defineComponent, ref, Ref, watch
} from 'vue';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import router, { propertyBaseRouter, propertySubRouter } from '@/router';
import { message } from '@/methods/rule';
import { community } from '@/data';
import init from '@/methods/building-apt-choose';
import { list, ListHeaderItem, RequestData } from '@/components/common/list';
import HttpRequest from '@/util/axios.config';
import { ChangeWordList } from '@/methods/setText';
import addTemplate from './addTemplate.vue';
import { MsgTemplateType, getAddMessageData } from './util';

interface AddFormType {
    MessageTitle: string;
    ClientType: string;
    Message: string;
    isAllMessage: number;
    Receiver: string;
    HaveDevice: string;
}
interface SelectType {
    Account: string;
    Email: string;
    Names: string;
    RoomName: string;
    UnitName: string;
}
export default defineComponent({
    components: {
        propertyBreadCrumb,
        addTemplate,
        list
    },
    setup() {
        const header: Ref< Array<ListHeaderItem> > = ref([]);
        const listRequestData: Ref<RequestData> = ref({
            url: '',
            param: {}
        });
        const projectType = community.ProjectType;
        watch(projectType, (type: ProjectType) => {
            const addMessageData = getAddMessageData()[type];
            header.value = addMessageData.header;
            listRequestData.value = addMessageData.requestData;
        }, {
            immediate: true
        });
        const breadHeaders = [{
            label: WordList.ProperAllTextGroupMessages,
            path: `/${propertyBaseRouter}/${propertySubRouter.message}`
        }, {
            label: WordList.ProperAllTextNew
        }];

        const rules = {
            MessageTitle: [{
                required: true,
                message: WordList.RuleMessageTitleEmpty,
                trigger: 'blur'
            }, {
                validator: message.checkTitle,
                trigger: 'blur'
            }],
            Message: [{
                required: true,
                message: WordList.RuleMessageEmpty,
                trigger: 'blur'
            }, {
                validator: community.isNew.value ? message.checkMessage : message.checkOldCommunityMessage,
                trigger: 'blur'
            }],
            HaveDevice: [{
                required: true,
                message: WordList.RuleReceiverEmpty,
                trigger: 'blur'
            }]
        };

        const addform: Ref<AddFormType> = ref({
            MessageTitle: '',
            ClientType: '0',
            Message: '',
            isAllMessage: 0,
            Receiver: '',
            HaveDevice: ''
        });

        const { buildOptions } = init();

        const updateList = ref(0);

        // 获取数据总数
        const total = ref(0);
        const getTotal = (res: number) => {
            total.value = res;
        };

        // 保存选择发送账号
        const selectDevice = (accounts: Array<string>, items: Array<SelectType>) => {
            addform.value.isAllMessage = items.length === total.value ? 1 : 0;
            addform.value.Receiver = JSON.stringify(accounts);
        };

        // 选择模版填入相应内容
        const choose = (item: MsgTemplateType) => {
            addform.value.MessageTitle = item.Title;
            addform.value.Message = item.Message;
        };

        const formRef: Ref<any> = ref(null);
        const submit = () => {
            if (JSON.parse(addform.value.Receiver).length === 0) {
                addform.value.HaveDevice = '';
            } else {
                addform.value.HaveDevice = 'true';
            }
            formRef.value.validate((valid: boolean) => {
                if (!valid) return false;
                HttpRequest.post(`v3/web/${projectType.value}/message/add`, addform.value, () => {
                    router.push(`/${propertyBaseRouter}/${propertySubRouter.message}`);
                });
                return true;
            });
        };

        return {
            listRequestData,
            selectDevice,
            buildOptions,
            breadHeaders,
            updateList,
            getTotal,
            formRef,
            addform,
            submit,
            header,
            choose,
            rules,
            ChangeWordList,
            projectType
        };
    }
});
