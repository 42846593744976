import { account } from '@/data';

const isOffice = () => Number(localStorage.getItem('grade')) === account.officeRole;
const isCommunity = () => Number(localStorage.getItem('grade')) === account.communityRole;
const isInstaller = () => Number(localStorage.getItem('grade')) === account.installerRole;

export default null;
export {
    isOffice,
    isCommunity,
    isInstaller
};