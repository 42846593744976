import { baseRouter, baseSubRouter } from '@/router/routes/base';
import { superBaseRouter, superSubRouter } from './routes/super';
import { propertyBaseRouter, propertySubRouter, propertyOldCommunitySubRouter } from './routes/property';
import { installerBaseRouter, installerSubRouter } from './routes/installer';
import { communityBaseRouter, communitySubRouter } from './routes/community';
import { distributorBaseRouter, distributorSubRouter } from './routes/distributor';

export {
    superBaseRouter,
    superSubRouter,
    propertyBaseRouter,
    propertySubRouter,
    installerBaseRouter,
    communityBaseRouter,
    communitySubRouter,
    installerSubRouter,
    distributorBaseRouter,
    distributorSubRouter,
    baseRouter,
    baseSubRouter,
    propertyOldCommunitySubRouter
};

export default null;